// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//ng serve --configuration=thb

import { Environment } from 'src/app/shared/types/environment.type';

export const environment: Environment = {
  code: 'thb',
  loginBackgroundImage: 'LoginBackground.thb.jpg',
  loginLogoImage: 'app_icon.png',
  appIcon: 'app_icon.png',
  hostUrl: 'https://top-api.manydigital.com/',
  production: true,
  clubName: 'Tophåndbold',
  primaryColor: '#224d56',
  secondaryColor: '#ffc400',
  firebaseConfig: {
    apiKey: 'AIzaSyDqhnN8ygngPqB-aId5-wsJj2ZFA4T7ZM0',
    authDomain: 'tophandbold-firebase.firebaseapp.com',
    projectId: 'tophandbold-firebase',
    storageBucket: 'tophandbold-firebase.appspot.com',
    messagingSenderId: '160827134477',
    appId: '1:160827134477:web:4b767986cdfcf1d4077b44',
    measurementId: 'G-XWMYE6R8F9',
  },
  sponsorEditable: true,
  statsVertical: 'Handball',
  pushDefaultLanguage: 'da',
  preferredLanguage: 'da',
  supportedLanguages: ['da'],
  useV2Survey: true,
  sentryDSN:
    'https://c4019ce4fd2a48bf8edfe6f3de2ee02e@o249228.ingest.sentry.io/5261460',
  dateformat: {
    shortEn: 'MM/dd/yy, h:mm a',
    mediumEn: 'MM/dd/yy, h:mm:ss a',
    mediumDateEn: 'MM/dd/yy',
    longDateEn: 'MMMM dd, y',
  },
};

export const featureList = {
  messageCentral: true,
};

export const richTextConfig = {
  style: {
    fontFamily: 'Featured Item, sans-serif',
    fontWeight: 400,
    textAlign: 'center',
    font: '16px Featured Item',
  },
  font: {
    list: {
      'Featured Item': 'Featured Item',
    },
  },
};

export const firebaseConfig = {
  apiKey: 'AIzaSyDqhnN8ygngPqB-aId5-wsJj2ZFA4T7ZM0',
  authDomain: 'tophandbold-firebase.firebaseapp.com',
  projectId: 'tophandbold-firebase',
  storageBucket: 'tophandbold-firebase.appspot.com',
  messagingSenderId: '160827134477',
  appId: '1:160827134477:web:4b767986cdfcf1d4077b44',
  measurementId: 'G-XWMYE6R8F9',
};

// export const environment : Environment= {
//   production: false,
//   apiBasePath: 'https://svk-api.manydigital.com',
//   clubName: 'Many Digital (HB)',
//   sponsorEditable: true,
//   teamId: '33',
//   competition: '8020100, 9825000',
//   // competition: '8020100',
//   statsVertical: 'Handball',
//   useV2Survey: true,
//   sentryDSN: 'https://c4019ce4fd2a48bf8edfe6f3de2ee02e@o249228.ingest.sentry.io/5261460'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
